//PaymentForm.js
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import axios from "axios";
import CardSection from "./CardSection";
import API from "../../Middleware/API";
import { useEffect, useState } from "react";
import SmallProgress from "./SmallProgress";
import Constants from "../../Support/Constants";
import { Link } from "react-router-dom";

const PaymentForm = ({ plan }) => {

    const stripe = useStripe();
    const elements = useElements();
    const [loading, setLoading] = useState(false);
    const [errMsg, setErrMsg] = useState(null);
    const [activePayment, setActivePayment] = useState(null);


    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        let cardElement;
        let error = false;
        let paymentMethod = {id : "123"};

        if(activePayment !== null && activePayment.avail === false){

            if (!stripe || !elements) {
                // Stripe.js has not loaded yet. Make sure to disable
                // form submission until Stripe.js has loaded.
                return;
            }
            // Get a reference to a mounted CardElement. Elements knows how
            // to find your CardElement because there can only ever be one of
            // each type of element.
            cardElement = elements.getElement(CardElement);
    
            // use stripe.createToken to get a unique token for the card
            // const { error, token } = await stripe.createToken(cardElement);
    
            const stripeData = await stripe.createPaymentMethod({
                type: "card",
                card: cardElement,
            });

            error = stripeData.error;
            paymentMethod = stripeData.paymentMethod

        }

        
        if (!error) {

            // Backend is not implemented yet, but once there isn’t any errors,
            // you can pass the token and payment data to the backend to complete
            // the charge

            API.post("payment", {
                payment: paymentMethod.id,
                plan: plan,
            })
                .then(async (resp) => {

                    let paymentId = resp.data.paymentId;

                    if (resp.data.actionRequired) {
                        // We perform 3D Secure authentication
                        const { paymentIntent, error } = await stripe.confirmCardPayment(
                            resp.data.clientSecret
                        );

                        if (error) {
                            console.log(error);
                            setLoading(false);
                            setErrMsg("Payment was failed due to authentication, please try again later.");
                        }

                        if (paymentIntent.status === "succeeded") {

                            API.get(`checkpayment/${paymentId}`)
                                .then((e) => {
                                    window.location.replace("/credits");
                                })
                                .catch((err) => {
                                    if (err.response.data.status === "failed") {
                                        setErrMsg(Constants.paymentFailedMsg);
                                    }
                                    console.log(err)
                                })

                        }
                        else {
                            setErrMsg(Constants.paymentFailedMsg)
                            setLoading(false);

                        }

                    } else {
                        // Simple HTTP Payment was successful
                        window.location.replace("/credits");
                    }

                    //alert("Your payment was successful");

                })
                .catch((err) => {
                    setErrMsg(err.response.data.msg);
                    setLoading(false)
                    console.log(err);
                });

        } else {
            setErrMsg(Constants.paymentFailedMsg);
            setLoading(false)
            console.log(error);
        }
    };

    useEffect(() => {

        API.get("checkactivepayment")
        .then((e) => {

            setActivePayment(e.data);

        })
        .catch((err) =>{
            setActivePayment(err.response.data);
            console.clear();
        });

    },[]);

    return (

        activePayment === null 

        ?

        <div className="d-flex justify-content-center w-100" >
          <SmallProgress />
        </div>
      

        :

        <form onSubmit={handleSubmit}>

            <div className="d-flex justify-content-center align-items-center flex-column w-100">

            {
                activePayment.avail === false

                ?
           
                <>

                    <div className="d-flex justify-content-between w-100">
                        <small className=""><b>Total Payment {Constants.plansPrice[plan]} USD for {plan}</b></small>
                        <div></div>
                    </div>

                    <div className="payment-card w-100 m-2">
                        <CardSection />
                    </div>

                    <div className="d-flex justify-content-between w-100">
                        <div></div>
                        <small className="text-success">Powered by stripe</small>
                    </div>

                    <div className="d-flex justify-content-center w-100 mt-2">
                        <small className="mb-2">You will be charged every month, you can cancel the subscription anytime.</small>
                        <div></div>
                    </div>

                </>

                :

                <>
                    <div className="d-flex justify-content-center w-100">
                        <small className=""><b>Total Payment {Constants.plansPrice[plan]} USD for {plan}</b></small>
                        <div></div>
                    </div>

                    <div className="d-flex justify-content-center w-100 my-3 flex-column align-items-center">
                        <p className="a-payment"><i>{activePayment.cardBrand.toUpperCase()}</i>&nbsp;&nbsp; **** **** **** {activePayment.last4Digit}</p>
                        <small className="mb-2">Your saved payment method, remove it from <Link to="/account">here.</Link></small>
                    </div>


                    <div className="d-flex justify-content-center w-100">
                        <small className="mb-2">You will be charged every month, you can cancel the subscription anytime.</small>
                        <div></div>
                    </div>
                </>

            }


                {

                    errMsg !== null

                        ?

                        <span className="text-danger mb-2 mt-3">{errMsg}</span>

                        :

                        loading

                            ?

                            <SmallProgress />
                            :

                            <button onClick={handleSubmit} type="button" className="btn app-btn mt-2 w-25">Pay</button>

                }


            </div>
        </form>
    );
};

export default PaymentForm;