import CloseCircleLineIcon from "remixicon-react/CloseCircleLineIcon";

const Compo404 = () => {

    return (

        <div className="center-container flex-column">
          <CloseCircleLineIcon size={30} />
          <h5 className="mt-3">Not Found</h5>
        </div>

    );


}

export default Compo404;