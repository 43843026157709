import { useEffect, useState } from "react";
import MainLayout from "../Components/Layouts/MainLayout";
import PaymentModal from "../Components/Modals/PaymentModal";
import CheckboxCircleLineIcon from "remixicon-react/CheckboxCircleLineIcon";
import Constants from "../Support/Constants";
import API from '../Middleware/API';

const Subscription = () => {

    const [showModal, setShowModal] = useState(0);
    const [plan, setPlan] = useState();

    const msg = `That's great ! We accept JAZZCASH, PAYPAL and CRYPTO as payment methods. To purchase your favorite plan please email at ${Constants.purchaseEmail}`;

    const showPaymentModal = (thisPlan) => {

        setPlan(thisPlan);
        setShowModal(showModal + 1);

    }



    return (

        <>

            <PaymentModal show={showModal} plan={plan} />


            <MainLayout credits title="Subscription">

                <div style={{ minHeight: "90vh" }} className="d-flex justify-content-center align-items-center flex-wrap">


                    <div className="card-container price-box d-flex flex-column justify-content-center align-items-center">

                        <h5 className="mt-3 mb-2">  Basic</h5>
                        <span className="mx-2 text-center price-word mb-3">For individuals looking to get started.</span>

                        <p className="price-text my-2"><span>15$</span>/Month</p>

                        <ul class="list-group list-group-flush my-3">
                            <li class="list-group-item"><CheckboxCircleLineIcon size={19} className="chk-icon" />250K Characters</li>
                            <li class="list-group-item"><CheckboxCircleLineIcon size={19} className="chk-icon" />Unlimited Projects</li>
                            <li class="list-group-item"><CheckboxCircleLineIcon size={19} className="chk-icon" />Active Customer Support</li>
                            <li class="list-group-item"><CheckboxCircleLineIcon size={19} className="chk-icon" />All 30+ Content Types</li>
                            <li class="list-group-item"><CheckboxCircleLineIcon size={19} className="chk-icon" />Powerful AI Algorithm</li>
                        </ul>

                        <button onClick={() => showPaymentModal("Basic") } type="btn" className="btn app-btn mb-3">Select</button>

                    </div>

                    <div className="card-container price-box d-flex flex-column justify-content-center align-items-center">

                        <h5 className="mt-3 mb-2">Premium</h5>
                        <span className="mx-2 text-center price-word mb-3">For bloggers, freelancers & small teams.</span>

                        <p className="price-text my-2"><span>29$</span>/Month</p>

                        <ul class="list-group list-group-flush my-3">
                            <li class="list-group-item"><CheckboxCircleLineIcon size={19} className="chk-icon" />600K Characters</li>
                            <li class="list-group-item"><CheckboxCircleLineIcon size={19} className="chk-icon" />Unlimited Projects</li>
                            <li class="list-group-item"><CheckboxCircleLineIcon size={19} className="chk-icon" />Active Customer Support</li>
                            <li class="list-group-item"><CheckboxCircleLineIcon size={19} className="chk-icon" />All 30+ Content Types</li>
                            <li class="list-group-item"><CheckboxCircleLineIcon size={19} className="chk-icon" />Powerful AI Algorithm</li>
                            <li class="list-group-item"><CheckboxCircleLineIcon size={19} className="chk-icon" />Full Blog Generator</li>

                        </ul>

                        <button onClick={() => showPaymentModal("Premium") } type="btn" className="btn app-btn mb-3">Select</button>

                    </div>

                    <div className="card-container price-box d-flex flex-column justify-content-center align-items-center">

                        <h5 className="mt-3 mb-2">Advanced</h5>
                        <span className="mx-2 text-center price-word mb-3">For professionals & marketing teams.</span>

                        <p className="price-text my-2"><span>49$</span>/Month</p>

                        <ul class="list-group list-group-flush my-3">
                            <li class="list-group-item"><CheckboxCircleLineIcon size={19} className="chk-icon" />Unlimited Characters <a target="_blank" href="https://contentcreato.com/terms/">(*FUP)</a></li>
                            <li class="list-group-item"><CheckboxCircleLineIcon size={19} className="chk-icon" />Unlimited Projects</li>
                            <li class="list-group-item"><CheckboxCircleLineIcon size={19} className="chk-icon" />Active Custom Support</li>
                            <li class="list-group-item"><CheckboxCircleLineIcon size={19} className="chk-icon" />All 30+ Content Types</li>
                            <li class="list-group-item"><CheckboxCircleLineIcon size={19} className="chk-icon" />Powerful AI Algorithm</li>
                            <li class="list-group-item"><CheckboxCircleLineIcon size={19} className="chk-icon" />Full Blog Generator</li>

                        </ul>

                        <button onClick={() => showPaymentModal("Advanced") }  type="btn" className="btn app-btn mb-3">Select</button>

                    </div>

                </div>

            </MainLayout>

        </>


    );
}

export default Subscription;