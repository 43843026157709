import { Link } from "react-router-dom";
import contentCreatoPng from "../../images/contentcreato.png";
import Constants from "../../Support/Constants";

const NavbarEasy = () => {

    return (
        <nav className="navbar navbar-auth">
            <div className="d-flex justify-content-center align-items-center w-100">
                <Link to="/" ><img className="me-1 mb-1" height={25} src={contentCreatoPng} /><a className="navbar-brand">{Constants.appName}</a></Link>
            </div>
        </nav>
    )
}

export default NavbarEasy;