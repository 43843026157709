
import MainLayout from "./MainLayout";

const TwoSidesLayout = ({ title, rightSideBarContent, showRight, children, ...props }) => {

    return (

        <MainLayout title={title} {...props} isTwoLayout>


        <div className="container-fluid">

            <div className="row t-w-c">

                <div className={showRight ? "col-9 ts-main" : "col-12 ts-main"}>

                   {children}

                </div>

                {
                    showRight &&
               

                    <div className="col-3 right-sb">

                        {/* <div className="page-heading">
                            <h4>Info</h4>
                        </div> */}

                        {rightSideBarContent}

                        {/* <div className="col-3 my-2 ">
                        
                        </div> */}
                    </div>

                }


            </div>

        </div>

        </MainLayout>

    );
}

export default TwoSidesLayout;