const initialState = "";

const System = (state = initialState, action) => {
    switch(action.type){
        case "SYSTEM" :
            return action.payload;
        default : 
            return state;
    }
}

export default System;