import { useState,useRef, useEffect } from "react";
import CloseLineIcon from "remixicon-react/CloseLineIcon";

const NoticeModal = ({show, msg}) => {

   const modal = useRef();

   const modalBtnClick = () => {
      modal.current.classList.remove("show-modal2");
   }

   const hideModal = () => {
      modal.current.classList.remove("show-modal2");
   }

   useEffect(() => {

       if(show) modal.current.classList.add("show-modal2");
       

   },[show]);

    return (

        <div ref={modal} className={"modal2"}>

        <div className='card modal2-box'>

            <CloseLineIcon onClick={hideModal} className="modal2-close-icon" size={26} />

            {
                msg &&
                <div className="m-2">
                    <p className="text-center">{msg}</p>
                </div>
            }
          

            <button onClick={modalBtnClick} type="button" className="btn app-btn">Got It</button>

        </div>

    </div>


    );
}

export default NoticeModal;