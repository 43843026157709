import { Link } from "react-router-dom";
import FolderLineIcon from "remixicon-react/FolderLineIcon";
import BankCardLineIcon from "remixicon-react/BankCardLineIcon";
import UserLineIcon from 'remixicon-react/UserLineIcon';
import ShutDownLineIcon from 'remixicon-react/ShutDownLineIcon';
import AwardLineIcon from 'remixicon-react/AwardLineIcon';
import Constants from "../../Support/Constants";
import contentCreatoPng from "../../images/contentcreato.png";

const Navbar = ({ title }) => {

    const getActive = (name) => {

        if (title.toLowerCase() === name) {
            return "nav-link active"
        } else {
            return "nav-link";
        }
    };

    const getActiveIcon = (name) => {
        if (title.toLowerCase() === name) {
            return "ac-icon"
        } else {
            return "non-ac-icon";
        }
    }


    return (

        <nav className="navbar navbar-expand-lg navbar-light">
            <div className="container-fluid">

                <Link className="navbar-brand" to="/projects"><img className="me-1 mb-1" height={25} src={contentCreatoPng} />{Constants.appName}</Link>
               

                <div className="collapse navbar-collapse" id="navbarNav">

                    <ul className="navbar-nav mx-auto">
                        <li className="nav-item me-4">
                          <div className="d-flex align-items-center">
                             <Link className={getActive('projects')} to="/projects"><FolderLineIcon size={16} className={getActiveIcon('projects') + " menu-icon"} /> Projects</Link>
                          </div>
                        
                        </li>
                        <li className="nav-item me-4">
                           <div className="d-flex align-items-center">
                                <Link className={getActive('credits')} to="/credits"><BankCardLineIcon size={16} className={getActiveIcon('credits') + " menu-icon"} /> Credits</Link>
                            </div>
                        </li>

                        <li className="nav-item me-4">
                           <div className="d-flex align-items-center">
                                <Link className={getActive('subscription')} to="/subscription"><AwardLineIcon size={16} className={getActiveIcon('subscription') + " menu-icon"} /> Subscription</Link>
                            </div>
                        </li>

                    </ul>

                </div>

                <div className="d-flex">
                    <Link className="navbar-brand mx-2" to="/account"><div className="acc-btn"><UserLineIcon size={16} /></div></Link>
                    <Link className="navbar-brand mx-2" to="/logout"><div className="logout-btn"><ShutDownLineIcon size={16} /></div></Link>
                </div>

            </div>
        </nav>

      

    );
}

export default Navbar;