import FolderLineIcon from "remixicon-react/FolderLineIcon";
import BankCardLineIcon from "remixicon-react/BankCardLineIcon";
import AwardLineIcon from 'remixicon-react/AwardLineIcon';
import { Link } from 'react-router-dom';

const BottomMenu = (props) => {

    const getActive = (name) => {

        if (props.active.toLowerCase() === name) {
        return "bmenu-active"
        } else {
        return "";
        }
    };

    return (

        <div className="bottom-menu">

        <ul className='b-menu-ul'>

          <Link to="/projects"><a><li className={getActive('projects')}>
            <div className="b-menu-btn">
              <FolderLineIcon size={16} />
              <span>Projects</span>
            </div>
          </li></a></Link>

          <Link to="/credits"><a><li className={getActive('credits')}>
            <div className="b-menu-btn">
              <BankCardLineIcon size={16} />
              <span>Credits</span>
            </div>
          </li></a></Link>

          <Link to="/subscription"><a><li className={getActive('subscription')}>
            <div className="b-menu-btn">
              <AwardLineIcon size={16} />
              <span>Subscription</span>
            </div>
          </li></a></Link>


        </ul>

      </div>


    );
}

export default BottomMenu;