import MainLayout from "../Components/Layouts/MainLayout";
import { useState, useRef, useEffect } from "react";
import PasswordModal from "../Components/Modals/PasswordModal";
import MediumProgress from "../Components/Need/MediumProgress";
import API from "../Middleware/API";
import { isEmpty } from "../Support/FormValidator";
import SmallProgress from "../Components/Need/SmallProgress";
import RemovePaymentModal from "../Components/Modals/RemovePaymentModal";
import { Link } from "react-router-dom";
import RemoveSubModal from "../Components/Modals/RemoveSubModal";
import Constants from "../Support/Constants";

const Account = () => {

    const [showModal, setShowModal] = useState(0);
    const [showPaymentModal, setShowPaymentModal] = useState(0);
    const [showSubModal, setShowSubModal] = useState(0);
    const formData = useRef();
    const [errMsg, setErrMsg] = useState("");
    const [okMsg, setOkMsg] = useState("");
    const [okFeedbackMsg, setOkFeedbackMsg] = useState("");
    const [data, setData] = useState(null);
    const [isSaving, setIsSaving] = useState(false);
    const [isAddingFeedback, setIsAddingFeedback] = useState(false);
    const [feedback, setFeedback] = useState("");
    const [isSysC, setIsSysC] = useState(0);
    const [paymentInfo, setPaymentInfo] = useState(null);
    const [subDetails, setSubDetails] = useState(null);

    const addFeedback = () => {

        if (feedback === "") {
            return;
        }

        if (okFeedbackMsg) {
            setOkFeedbackMsg("");
        }

        setIsAddingFeedback(true);

        API.post("addfeedback", {
            feedback: feedback
        })
            .then((e) => {

                setIsAddingFeedback(false);
                setOkFeedbackMsg("Feedback submitted ! Thank you for your feedback.");
                setFeedback("");

            })
            .catch((err) => {
                setIsAddingFeedback(false);
                console.clear();
            })
    }


    const Save = (event) => {

        event.preventDefault();

        if (isEmpty(['name'], formData.current)) {
            setErrMsg("Please fill your name !");
            return;
        }

        const { name, apiKey } = formData.current;

        if (errMsg) {
            setErrMsg("");
        }

        if (okMsg) {
            setOkMsg("")
        }

        setIsSaving(true);

        API.post("accountinfo", {
            name: name.value,
            apiKey: apiKey.value,
        })
            .then((e) => {
                setOkMsg(e.data.msg);
                setIsSaving(false);
            })
            .catch((err) => {
                setErrMsg(err.response.data.msg)
                setIsSaving(false);
            });


    }


    useEffect(() => {

        API.get("accountinfo")
            .then((e) => {
                setIsSysC(e.data.isSysC);
                setData(e.data.userInfo);
                setPaymentInfo(e.data.paymentInfo);
                setSubDetails(e.data.subscriptionDetails)
            })
            .catch((err) => {

            });

    }, []);


    return (

        <>

            {/* Password chnge modal */}

            <PasswordModal show={showModal} />

            <RemovePaymentModal show={showPaymentModal} />

            <RemoveSubModal show={showSubModal} />

            <MainLayout account title="Account">

                {
                    data === null ?

                        <div className="center-container">
                            <MediumProgress />
                        </div>


                        :


                        <div className="acc-pg-c card-container pb-3 mb-3" style={{ height: "auto" }}>


                            <form className="mx-3 my-3" ref={formData}>

                                <h5 className="mb-3 pt-3">Account Information</h5>

                                <div className="mb-3">

                                    <label className="form-label">Name</label>
                                    <input name="name" type="text" defaultValue={data.name} className="form-control" />

                                </div>

                                <div className="mb-3">

                                    <label className="form-label">Email</label>
                                    <input type="email" defaultValue={data.email} className="form-control" disabled />

                                </div>


                                <div style={{ display: isSysC == 0 ? "block" : "none" }} className="mb-3">

                                    <label className="form-label">API Key</label>
                                    <input name="apiKey" type="text" defaultValue={data.apiKey} className="form-control" />

                                </div>




                                {
                                    errMsg &&
                                    <p className="my-2 text-danger">{errMsg}</p>
                                }

                                {
                                    okMsg &&
                                    <p className="my-2 text-success">{okMsg}</p>
                                }



                                {
                                    isSaving ?

                                        <div className="d-inline-block ms-2">
                                            <SmallProgress />
                                        </div>

                                        :

                                        <button type="submit" className="btn app-btn" onClick={Save}>Save</button>

                                }

                            </form>

                            {
                                (paymentInfo !== null && paymentInfo.avail === true)

                                    ?

                                    <div className="mx-3 my-3 mt-4">

                                        <h5 className="mb-3 pt-3">Payment Details</h5>

                                        <p><i>{paymentInfo.cardBrand.toUpperCase()}</i>&nbsp;&nbsp; **** **** **** {paymentInfo.last4Digit}</p>

                                        <button onClick={() => setShowPaymentModal(showPaymentModal + 1)} type="button" className="btn app-btn">Remove</button>


                                    </div>

                                    :

                                    <div className="mx-3 my-3 mt-4">

                                        <h5 className="mb-3 pt-3">Payment Details</h5>

                                        <p>You don't have any payment details set. <Link to="/subscription">Subscribe to a plan </Link>to add your payment details.</p>

                                    </div>


                            }

                            {

                                subDetails !== null && subDetails.isActive === true

                                    ?

                                    <div className="mx-3 my-3 mt-4">

                                        <h5 className="mb-3 pt-3">Subscription Details</h5>

                                        <p>Your current subscribed plan is {subDetails.plan}.</p>

                                        <button onClick={() => setShowSubModal(showSubModal + 1)} type="button" className="btn app-btn">Cancel Subscription</button>


                                    </div>

                                    :

                                    <div className="mx-3 my-3 mt-4">

                                        <h5 className="mb-3 pt-3">Subscription Details</h5>

                                        <p>You don't have any subscription. <Link to="/subscription">Subscribe to a plan</Link></p>

                                    </div>
                            }




                            <div className="mx-3 my-3 mt-4">

                                <h5 className="mb-3 pt-3">Feedback</h5>

                                <textarea value={feedback} onChange={(e) => setFeedback(e.target.value)} className="form-control mb-3" style={{ minHeight: "250px" }}></textarea>

                                {
                                    okFeedbackMsg &&
                                    <p className="my-2 text-success">{okFeedbackMsg}</p>
                                }

                                {
                                    isAddingFeedback ?

                                        <div className="d-inline-block ms-2">
                                            <SmallProgress />
                                        </div>

                                        :

                                        <button type="submit" className="btn app-btn" onClick={addFeedback}>Submit</button>

                                }


                            </div>

                            <div className="mx-3 my-3 mt-4">

                                <h5 className="mb-3 pt-3">Security</h5>

                                <button onClick={() => setShowModal(showModal + 1)} type="button" className="btn app-btn">Change Password</button>


                            </div>

                            <div className="mx-3 my-3 mt-4">

                                <h5 className="mb-3 pt-3">Support</h5>

                                <span>You can contact us by {Constants.supportEmail}</span>
                            </div>



                        </div>

                }


            </MainLayout>


        </>


    );
}

export default Account;