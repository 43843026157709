import MediumProgress from "../Components/Need/MediumProgress";
import Constants from "../Support/Constants";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import API from "../Middleware/API";
import NavbarEasy from "../Components/Need/NavbarEasy";

const Logout = () => {

    const navigate = useNavigate();

   // navigate("/login", { replace: true });

    useEffect(() => {

        API.get('logout')
        .then((e) => {

            if(e.data.status ==="success"){
                navigate("/login", { replace: true });
            }

        })
        .catch((err) => {
            console.log(err);
        })

    },[]);


    return (
        <>
            <NavbarEasy />

            <div className="main-container">

                {/* <div className="d-flex justify-content-center align-items-center h-100 w-100"> */}
                <MediumProgress />
           </div>

       </>
    )
}

export default Logout;