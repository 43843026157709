import { useEffect, useState } from "react";
import MainLayout from "../Components/Layouts/MainLayout";
import API from "../Middleware/API";
import MediumProgress from "../Components/Need/MediumProgress";
import AddCardModal from "../Components/Modals/AddCardModal";
import SettingsLineIcon from "remixicon-react/SettingsLineIcon";
import MoneyDollarCircleLineIcon from "remixicon-react/MoneyDollarCircleLineIcon";
import TimeLineIcon from "remixicon-react/TimeLineIcon";
import CalendarLineIcon from "remixicon-react/CalendarLineIcon";
import BankCardLineIcon from "remixicon-react/BankCardLineIcon";

const Credits = () => {

    const [showModal, setShowModal] = useState(0);

    const initData = {
        subscription: false,
        credits: 0,
        usedCredits: 0,
        givenCredits: 0,
        subscriptionEnd: true,
        subscriptionName: "",
        moneySaved: "0 $",
        hoursSaved: 0,
        loaded: false,
        subscriptionDetails: {
            credits: 0,
            days: 0,
            price: "0 $",
        }
    }

    const [data, setData] = useState(initData);

    useEffect(() => {

        API.get("credits")
            .then((e) => {
                setData({ loaded: true, ...e.data });
            })
            .catch((err) => {

            });

    }, []);

    return (

        <>

            <AddCardModal show={showModal} />


            <MainLayout credits title="Credits">

                {
                    data.loaded === false ?

                        <div className="center-container">
                            <MediumProgress />
                        </div>


                        :

                        <>

                            <h5 className="m-3 mx-4">Stats : </h5>

                            <div className='m-3 d-flex flex-wrap justify-content-start '>

                                <div className="card mx-2 c-card">

                                    <div className="card-body">
                                        <h6 className="my-3">Credits Remaining</h6>
                                        <div className="d-flex flex-row justify-content-start">
                                            <BankCardLineIcon size={19} className="mb-2 me-1" />
                                            <h6>{data.credits}</h6>
                                        </div>
                                    </div>

                                </div>

                                <div className="card mx-2 c-card">

                                    <div className="card-body">
                                        <h6 className="my-3">Credits Used</h6>
                                        <div className="d-flex flex-row justify-content-start">
                                            <BankCardLineIcon size={19} className="mb-2 me-1" />
                                            <h6>{data.usedCredits}</h6>
                                        </div>
                                    </div>

                                </div>

                                <div className="card mx-2 c-card">

                                    <div className="card-body">
                                        <h6 className="my-3">Hours Saved</h6>
                                        <div className="d-flex flex-row justify-content-start">
                                            <TimeLineIcon size={19} className="mb-2 me-1" />
                                            <h6>{data.hoursSaved}</h6>
                                        </div>
                                    </div>

                                </div>

                                <div className="card mx-2 c-card">

                                    <div className="card-body">
                                        <h6 className="my-3">Saved</h6>
                                        <div className="d-flex flex-row justify-content-start">
                                            <MoneyDollarCircleLineIcon size={19} className="mb-2 me-1" />
                                            <h6>{data.moneySaved}</h6>
                                        </div>
                                    </div>

                                </div>

                            </div>

                            {/* <div className="m-3 mx-4">

                <span className="text-success">To purchase more credits, contact us.</span>

            </div> */}

                            <h5 className="m-3 mx-4 mt-5">Subscription Details : </h5>

                            <div className='m-3 d-flex flex-wrap justify-content-start '>

                                <div className="card mx-2 c-card">

                                    <div className="card-body">
                                        <h6 className="my-3">Credits</h6>
                                        <div className="d-flex flex-row justify-content-start">
                                            <BankCardLineIcon size={19} className="mb-2 me-1" />
                                            <h6>{data.subscriptionDetails.credits}</h6>
                                        </div>
                                    </div>

                                </div>

                                <div className="card mx-2 c-card">

                                    <div className="card-body">
                                        <h6 className="my-3">Price</h6>
                                        <div className="d-flex flex-row justify-content-start">
                                            <MoneyDollarCircleLineIcon size={19} className="mb-2 me-1" />
                                            <h6>{data.subscriptionDetails.price}</h6>
                                        </div>
                                    </div>

                                </div>

                                <div className="card mx-2 c-card">

                                    <div className="card-body">
                                        <h6 className="my-3">Days</h6>
                                        <div className="d-flex flex-row justify-content-start">
                                            <CalendarLineIcon size={19} className="mb-2 me-1" />
                                            <h6>{data.subscriptionDetails.days}</h6>
                                        </div>
                                    </div>

                                </div>

                            </div>

                            <div className="m-3 mx-4">
                                {

                                    (data.isSubActive == 1 && data.subscriptionEnd !== 0)
                                    ?
                                    <span className="text-success">Next Billing cycle on {data.subscriptionEnd}</span>

                                    :

                                    data.subscriptionEnd !== 0

                                    ?

                                    <span className="text-success">Your plan will expire on {data.subscriptionEnd}</span>

                                    :

                                    <></>

                                }

                            </div>

                            <button onClick={() => setShowModal(showModal + 1)} type="button" className="mx-4 btn app-btn">Add Subscription Card</button>


                        </>

                }

            </MainLayout>

        </>


    );
}

export default Credits;