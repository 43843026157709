import MainLayout from "../../Components/Layouts/MainLayout";
import TwoSidesLayout from "../../Components/Layouts/TwoSidesLayout";
import { useParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import API from "../../Middleware/API";
import MediumProgress from "../../Components/Need/MediumProgress";
import Compo404 from "../../Components/Need/Compo404";
import { useSelector, useDispatch } from "react-redux";
import { Writer } from "../../Redux/actions";
import SmallProgress from "../../Components/Need/SmallProgress";
import Constants from "../../Support/Constants";
import NoticeModal from "../../Components/Modals/NoticeModal";
import NoticeHTMLModal from "../../Components/Modals/NoticeHTMLModal";
import { useLocation } from "react-router-dom";

import { CKEditor } from '@ckeditor/ckeditor5-react';

import ClassicEditor from '@ckeditor/ckeditor5-build-classic';


const RightSideBarContent = ({ data, isLoaded, id, editor }) => {

    const [fields, setFields] = useState(data);
    const [errMsg, setErrMsg] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [descTitle, setDescTitle] = useState("");
    const writer = useSelector((state) => state.Writer);
    const writerInterval = useRef();
    const [allowClick, setAllowClick] = useState(true);
    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState(0);

    const keywordsInput = useRef();
    const toneOfVoiceInput = useRef();
    const hideValues = ['blog outline', 'answer', 'blog ideas', 'seometatitle', 'completion','product description', 'seometadesc', 'ad','notification', 'job desc', 'interview questions', 'bio', 'pas', 'aida', 'google ad','completion', 'paraphrase', 'summarize', 'reply review', 'reply message'];
   

    useEffect(() => {

        return () => clearTimeout(writerInterval.current);

    }, []);


    //use for typewriter effect ! ========================
    let tempContent = "";
    let count = 0;
    const speed = 30;

    var typeWriter = () => {

        if (count < tempContent.length) {

            if (tempContent[count] === "\n") {

                const content = '<br/>';
                const viewFragment = editor.data.processor.toView(content);
                const modelFragment = editor.data.toModel(viewFragment);
                editor.model.insertContent(modelFragment);

            } else {

                editor.model.change(writer => {
                    writer.insert(tempContent[count] + " ", editor.model.document.selection.getFirstPosition());
                });

            }

            count++;
            writerInterval.current = setTimeout(typeWriter, speed);

        }
        else {
            setAllowClick(true);
            // dispatch(Writer(editor.getData()));
            setErrMsg("");
        }
    }
    // ===============================================

    const addToEditor = (text) => {

        // if(editor.data.get() === ""){

        //     editor.model.change(writer => {
        //         writer.insert(text, 'end');
        //     });

        //     return;
        // }

        text = text.replace(/\n/g, "<br />");

        const content = text;
        const viewFragment = editor.data.processor.toView(content);
        const modelFragment = editor.data.toModel(viewFragment);
        editor.model.insertContent(modelFragment, editor.model.document.selection.getFirstPosition());

        // editor.model.change(writer => {
        //     writer.insert(text, editor.model.document.selection.getFirstPosition());
        // });
    }

    const btnClick = () => {

        if (!fields.description) {
            return;
        }

        if (allowClick === false) {
            setErrMsg("I am still typing.");
            return;
        }

        global.allowSave = false;

        setIsLoading(true);

        API.post(`ai/writer/${id}`, {
            description: fields.description,
            keywords: fields.keywords,
            toneOfVoice: fields.toneOfVoice,
            content: editor.data.get(),
            type: fields.type,
        })
            .then((e) => {

                //setAllowClick(false);
                setIsLoading(false);
                addToEditor(e.data.content);
                global.allowSave = true;
                // tempContent = e.data.content;
                // tempContent = tempContent.replace(/\n/g, " \n ");
                // tempContent = tempContent.split(" ");
                // typeWriter();

            })
            .catch((err) => {

                try{

                    setErrMsg(err.response.data.msg);
                    setIsLoading(false);
                    console.clear();
                    setShowModal(showModal + 1)
                }
                catch(err){
                    setErrMsg("Something went wrong, please report this issue.");
                    setIsLoading(false);
                    console.clear();
                    setShowModal(showModal + 1);
                }
              
            })

        if (errMsg) {
            setErrMsg("");
        }
    }

    const onTypeChange = (e) => {

        setFields({ ...fields, type: e.target.value });

        if(hideValues.includes(e.target.value)){
            keywordsInput.current.disabled = true;
            toneOfVoiceInput.current.disabled = true;
        }else{
            keywordsInput.current.disabled = false;
            toneOfVoiceInput.current.disabled = false;
        }

        setDescTitle(Constants.descTitle[e.target.value]);
    
    }

    //for init types
    useEffect(() => {

        if(hideValues.includes(fields.type)){
            keywordsInput.current.disabled = true;
            toneOfVoiceInput.current.disabled = true;
        }else{
            keywordsInput.current.disabled = false;
            toneOfVoiceInput.current.disabled = false;
        }

        setDescTitle(Constants.descTitle[fields.type]);

    },[]);

    return (

        <>

        <NoticeModal show={showModal} msg={errMsg} />

        {

           isLoaded ?

            <div className="p-3 card-container r-sidebar">

                <div className="mb-3">

                    <label className="form-label">{descTitle}</label>

                    <textarea name="description" style={{ height: 100 }}
                        onChange={(e) => (e.target.value.length <= Constants.descriptionLength) && setFields({ ...fields, description: e.target.value })}
                        type="text" className="form-control"
                        placeholder="" value={fields.description} >
                    </textarea>

                    <small className="app-small">{fields.description.length}/{Constants.descriptionLength}</small>

                </div>

                <div className="mb-3">

                    <label className="form-label">Type</label>

                    <select name="type" value={fields.type} onChange={(e) => onTypeChange(e)} className="form-select" aria-label="Default select example">
                    <option value="intro">Blog Intro</option>
                                <option value="main content">Blog Section</option>
                                <option value="conclusion">Blog Conclusion</option>
                                <option value="blog outline">Blog Outline</option>
                                <option value="blog ideas">Blog Ideas</option>
                                <option value="long form">Blog Long Content</option>
                                <option value="seometadesc">SEO Meta Description</option>
                                <option value="seometatitle">SEO Meta Title</option>
                                <option value="video ideas">Video Ideas</option>
                                <option value="video desc">Video Description</option>
                                <option value="video channel desc">Video Channel Description</option>
                                <option value="answer">Answer</option>
                                <option value="email">Email</option>
                                <option value="bio">Profile Bio</option>
                                <option value="aida">AIDA</option>
                                <option value="pas">PAS</option>
                                <option value="ad">Ad</option>
                                <option value="google ad">Google Search Ad</option>
                                <option value="call to action">Call to action</option>
                                <option value="business idea pitch">Business Idea Pitch</option>
                                <option value="preview">Product Review</option>
                                <option value="product desc">Product Description</option>
                                <option value="job desc">Job Description</option>
                                <option value="interview questions">Interview Questions</option>
                                <option value="notification">Notification</option>
                                <option value="reply message">Reply to message</option>
                                <option value="reply review">Reply to review</option>
                                <option value="completion">Expand</option>
                                <option value="paraphrase">Paraphrase</option>
                                <option value="summarize">Summarize</option>
                        
                    </select>

                </div>


                <div className="mb-3">
                    <label className="form-label">Keywords (Optional)</label>

                    <textarea
                        ref={keywordsInput}
                        name="keywords"
                        onChange={(e) => e.target.value.length <= Constants.keywordsLength && setFields({ ...fields, keywords: e.target.value })}
                        value={fields.keywords} type="text"
                        className="form-control" placeholder="">
                    </textarea>

                    <small className="app-small">{fields.keywords.length}/{Constants.keywordsLength}</small>

                </div>

                <div className="mb-3">

                    <label className="form-label">Tone (Optional)</label>

                    <textarea
                        ref={toneOfVoiceInput}
                        name="toneOfVoice"
                        onChange={(e) => e.target.value.length <= Constants.toneOfVoiceLength && setFields({ ...fields, toneOfVoice: e.target.value })}
                        type="text" className="form-control"
                        placeholder="" value={fields.toneOfVoice}>
                    </textarea>

                    <small className="app-small">{fields.toneOfVoice.length}/{Constants.toneOfVoiceLength}</small>

                </div>

                {/* {
                    errMsg && <div className="mb-3"><span className="text-danger">{errMsg}</span></div>
                } */}

                {
                    isLoading ?

                        <div className="d-inline-block ms-2">
                            <SmallProgress />
                        </div>


                        :

                        <button onClick={btnClick} type="button" className="btn app-btn">Write</button>
                }


            </div>

            :

            <></>

        }

        </>
        

    )
}

const BlogWriting = () => {


    const { id } = useParams();
    const [is404, setIs404] = useState(false);


    //for info modal on new project START
    const [showInfoModal, setShowInfoModal] = useState(0);
    const location = useLocation();
    const [infoMsg, setInfoMsg] = useState("");

    useEffect(() => {

       setInfoMsg(`<h5>Hey, here is the guide on how to get most of ContentCreato.</h5><br />
       <p>
       1-Before generating AI Content, please point the cursor on the text editor to the place where you want the content should be written.<br /><br/> For example, if you want content after a heading then before generating content point the cursor there.
       <br /> <br />
       2-Our text editor is scrollable, if you don’t point the cursor ContentCreato will automatically generate new content at the bottom of the text editor, you can scroll the text editor to see new text.
       <br /> <br />
       3-Keep the inputs small and meaningful. Don't put the whole paragraph.
       <br /> <br />
       4-We have 12+ content types so check all of them, and use which suits you.
       <br /> <br />
       5-Our Blog section and Long Content types are amazing, you can use them for better content for your blog.
       <br /> <br />
       6- Do you want to answer a question in your blog?  We have a type ‘answer’ where you can just put questions and get answers.
       <br/><br/>
       7-If you have any questions feel free to email at <b>support@contentcreato.com</b></br>
       </p>`);

    try {
        if(location.state.msg == "new-project"){
            setShowInfoModal(showInfoModal + 1);
        }
    }
    catch (err) {
        
    }

    },[]);
    //for info modal on new project END


    const editorOpt = {
        placeholder: { text: "" },
        keyboardCommands: false,
        toolbar: { buttons: ['bold', 'italic', 'underline'] }
    }

    const [data, setData] = useState({
        content: "",
        title: "",
        details: {
            keywords: "",
            toneOfVoice: "",
            description: "",
            type: "",
        },
        isLoaded: false,
    });

    const writer = useSelector((state) => state.Writer);
    const dispatch = useDispatch();
    const writerChangeTimeOut = useRef(null);
    const [editor, setEditor] = useState(null);
    const [initPage, setInitPage] = useState(0);
    const [saveMsg, setSaveMsg] = useState({
        class : "app-success",
        msg : "",
    });

    useEffect(() => {

        global.allowSave = true;

        API.get(`details/writer/${id}`)
            .then((e) => {

                setData({ isLoaded: true, ...e.data.data });

                dispatch(Writer(e.data.data.content));

            })
            .catch((err) => {
                console.log(err)

                if (err.response.status === 404) {
                    setIs404(true);
                }
                console.clear();
            });

    }, []);

    useEffect(() => {

        if (editor !== null) {
            editor.data.set(writer.replace(/\n/g, "<br>"))
        }
    }, [editor]);


    const saveToServer = () => {

        if(global.allowSave === false){
            return;
        }

        setSaveMsg({class : "app-success", msg : "Saving..."});
        //console.log(document.getElementsByName("description")[1].value)

        API.post(`save/writer/${id}`, {
            description: document.getElementsByName("description")[1].value,
            keywords: document.getElementsByName("keywords")[0].value,
            toneOfVoice: document.getElementsByName("toneOfVoice")[0].value,
            content: editor.data.get(),
            type: document.getElementsByName("type")[0].value,
        })
        .then((e) => {

            if(e.data.status === "success"){
                setSaveMsg({class : "app-success", msg : "Saved !"});
            }
            else{
                setSaveMsg({class : "app-error", msg : "Saving Error !"});
            }

        })
        .catch((err) => {
            setSaveMsg({class : "app-error", msg : "Saving Error !"});
            console.clear();
        })

        //console.log("Saved to server !")
    }

    const onWriterChange = (event, editor) => {

        if(initPage === 0){
            setInitPage(1);
            return;
        }

        //dispatch(Writer(editor.getData()));

        // console.log(writerChangeTimeOut.current)
        if (writerChangeTimeOut.current === null) {
           // console.log("1")
            writerChangeTimeOut.current = setTimeout(saveToServer, 5000);
        }
        else {
            //console.log("2")
            clearTimeout(writerChangeTimeOut.current);
            writerChangeTimeOut.current = null;
            writerChangeTimeOut.current = setTimeout(saveToServer, 5000);
        }

    }



    return (

        <>

           <NoticeHTMLModal show={showInfoModal} msg={infoMsg} />

            <TwoSidesLayout showRight={(!is404 && data.isLoaded)}
                title="Blog Writing" projects
                rightSideBarContent={<RightSideBarContent editor={editor} id={id} isLoaded={data.isLoaded} data={data.details} />} >

                {
                    is404 ?


                        <Compo404 />


                        :

                        !data.isLoaded ?

                            <div className="center-container">
                                <MediumProgress />
                            </div>

                            :

                            <>

                            <div className="writer-container card-container">

                                <div className="w-main">
                                    {/* <label className="form-label">{data.title}</label> */}

                                    <CKEditor
                                        editor={ClassicEditor}
                                        // data={writer}
                                        onReady={(e) => setEditor(e)}
                                        onChange={onWriterChange}
                                        config={{
                                            allowedContent: true,
                                            toolbar: [['Bold'], ['Italic'], ['Underline'], ['Heading'], ['BulletedList'], ['NumberedList'], ['Link']]
                                        }}
                                    />

            

                                    {/* <Editor
                                    tag="div"
                                    className="writerInput writer-ta"
                                    text={writer}
                                    onChange={onWriterChange}
                                    options={editorOpt}
                                /> */}

                                    {/* <textarea onChange={(e) => dispatch(Writer(e.target.value))} value={writer} className="writerInput form-control writer-ta"></textarea> */}
                                </div>

                            </div>

                            <label className="lb-info my-1">Point the cursor where you want to get AI Content.</label>
                            <br />
                            <label className={`lb-info form-label ${saveMsg.class}`}>{saveMsg.msg}</label>

                            </>
                }

            </TwoSidesLayout>

        </>

    );
}

export default BlogWriting;