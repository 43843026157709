import { useState,useRef, useEffect } from "react";
import CloseLineIcon from "remixicon-react/CloseLineIcon";
import SmallProgress from "../Need/SmallProgress";
import API from "../../Middleware/API";

const RemoveSubModal = ({show, id}) => {

   const [showModalBtn , setShowModalBtn] = useState(true);
   const [errMsg, setErrMsg] = useState("");
   const modal = useRef();

   const modalBtnClick = () => {

    setShowModalBtn(false);

    API.get(`cancelsubscription`)
    .then((e) => {
        window.location.replace("/account");
    })
    .catch((err) => {
        setShowModalBtn(false);
        setErrMsg(err.response.data.msg)
    })

   }

   const hideModal = () => {
      modal.current.classList.remove("show-modal2");
   }

   useEffect(() => {

       if(show) {
           modal.current.classList.add("show-modal2");
       }

   },[show]);

    return (

        <div ref={modal} className={"modal2"}>

        <div className='card modal2-box'>

            <CloseLineIcon onClick={hideModal} className="modal2-close-icon" size={26} />

            <h6 className='text-center mb-4'>Are you sure you want to unsubscribe your current plan ? <br />This mean you won't be charged next month.</h6>

            {
                errMsg && <span className="text-danger mb-2">{errMsg}</span>
            }
            

            {
                    showModalBtn
                    ?
                    <div className="d-flex">
                        <button onClick={modalBtnClick} type="button" className="btn app-btn">Yes</button>
                        <button onClick={hideModal} type="button" className="btn app-btn ms-3">No </button>
                    </div>
                    :
                    errMsg !== ""
                    ?
                    <></>
                    :
                    <SmallProgress />
            }

        </div>

    </div>


    );
}

export default RemoveSubModal;