import { Link } from "react-router-dom";
import centencePng from "../images/contentcreato.png";
import Constants from "../Support/Constants";
import notFoundPng from "../images/notfound.png";

const NotFound = () => {
    

    return (
    
      <>
            <nav className="navbar navbar-auth">
                <div className="d-flex justify-content-center align-items-center w-100">
                    <Link to="/" ><img className="me-1 mb-1" height={25} src={centencePng} /><a className="navbar-brand">{Constants.appName}</a></Link>
                </div>
            </nav>

            <div className="main-container">
              
              <img src={notFoundPng} width={75} />
              <p className="my-3">The URL you are trying to access was not found on the server.</p>

            </div>

      </>


    );
}

export default NotFound;