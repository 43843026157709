const Constants = {

    //end with slash !
    // apiUrl : "http://localhost:8000/",
    // appLink : "http://localhost:3000/",
    apiUrl : "https://api.contentcreato.com/",
    appLink : "https://app.contentcreato.com/",
    appName : "ContentCreato",
    purchaseEmail : "purchase@contentcreato.com",
    supportEmail : "support@contentcreato.com",
    bmenuSize : 20,
    stripeKey : "pk_live_51Kr3RiSJxxVH2Sut0WQwXezsx1YRBuCHu87mVCkCfMn3rGKpJRcW9pD9KJolDzoFpjIHLFdlRdKaPBYhip3Cb45o00RAWwoOAL",

    //blog wriitng
    descriptionLength : 220,
    keywordsLength : 70,
    toneOfVoiceLength : 20,
    descTitle : {
        "blog outline" : "Topic",
        "blog ideas" : "Topic",
        "conclusion" : "Topic",
        "intro" : "Topic",
        "blog section" : "Topic",
        "long content" : "Topic",
        "completion" : "Context",
        "product description" : "About Product",
        "answer" : "Question",
        "seometadesc" : "Article Title",
        "marketing email" : "Description",
        "ad" : "Product Description",
        "bio" : "About Person"
    },

    plansPrice : {
        "Plan 1" : 15,
        "Plan 2" : 29,
        "Plan 3" : 49,
    },

    paymentFailedMsg : "Payment was failed, please try again by refreshing the page."
}

export default Constants;