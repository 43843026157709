import { useEffect, useRef, useState } from "react";
import { isEmpty } from "../Support/FormValidator";
import axios from "axios";
import Constants from "../Support/Constants";
import { Link } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";
import { isEmail } from "../Support/FormValidator";
import Key from "../Support/Key";
import ProgressPage from "../Components/Need/ProgressPage";
import ForgotPassModal from "../Components/Modals/ForgotPassModal";
import NavbarEasy from "../Components/Need/NavbarEasy";

const Login = () => {

    const [isLoading, setIsLoading] = useState(false);
    const loginForm = useRef();
    const [showModal, setShowModal] = useState(0);

    const [isLoggedIn, setIsLoggedIn] = useState(null);

    useEffect(() => {

        axios.get(Constants.apiUrl + `isloggedin?key=${Key()}`, {
            withCredentials : true
        })
        .then((e) => {

            if(e.data.isLoggedIn === true){
                window.location.replace("/projects");
                setIsLoggedIn(true);
            }else{
                setIsLoggedIn(false);
            }

        })
        .catch((err) => {

            setIsLoggedIn(false);
            console.clear();
        });

    },[]);
    

    const location = useLocation();
    const navigate = useNavigate();

    let registerMsg = "";

    try {
        registerMsg = location.state.msg;
        // console.log(location.state.msg)
    }
    catch (err) {
        registerMsg = "";
    }

    const [msg, setMsg] = useState({ color: "#2962FF", msg: registerMsg });

    const onLogin = (event) => {

        event.preventDefault();

        //validating form details
        if (isEmpty(['email', 'password'], loginForm.current)) {
            setMsg({ color: "red", msg: "Please fill all the fields !" });
            return;
        }

        const { email, password } = loginForm.current;

        if(!isEmail(email.value)){
            setMsg({ color: "red", msg: "Invalid email !" });
            return;
        }

        setIsLoading(true);

        axios.post(Constants.apiUrl + `login?key=${Key()}`, {
            email: email.value,
            password: password.value,
        },
            {
                withCredentials: true,
            })
            .then((e) => {

                if (e.data.status == "success") {

                    navigate("/projects", { replace: true });
                
                }
                else {
                    setIsLoading(false);
                }

                return;

            })
            .catch((err) => {

                setIsLoading(false);
                try {
                    setMsg({ color: 'red', msg: err.response.data.msg });
                }
                catch (err) {

                }
                console.clear();

            });


    }

    return (

        isLoggedIn === null 

        ? <ProgressPage /> 

        : !isLoggedIn && 

        <>

          <ForgotPassModal show={showModal} />

           <NavbarEasy />

            <div className="main-container">

                <div className="form-box">

                    <div className="d-flex justify-content-center align-items-center login-card">

                        <form ref={loginForm}>

                            <h5 className="mb-2">Sign In</h5>

                            <span className="msg mb-2" style={{ color: msg.color }}>{msg.msg}</span>

                            <div className="mb-3 mt-2">
                                <label className="form-label">Email</label>
                                <input type="email" className="form-control" name="email" />
                            </div>


                            <div className="mb-3">
                                <label className="form-label">Password</label>
                                <input type="password" className="form-control" name="password" />
                            </div>

                            {
                                    isLoading
                                    ?
                                    <div className="small-progress mt-4"></div>
                                    :
                                    <button type="submit" className="btn app-btn w-100 mt-2 mb-2" onClick={onLogin}>Sign In</button>
                            }

                            <p className="my-2">Forgot password ? <span onClick={() => setShowModal(showModal + 1)} className="text-primary reset-now">Reset Now</span></p>

                            <p className="">Don&apos;t have an account?  <Link to="/register">Sign Up</Link></p>
                        </form>

                    </div>
                </div>

            </div>

        </>
    )
}


export default Login;