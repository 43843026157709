import AddCircleLineIcon from "remixicon-react/AddCircleLineIcon";
import DeleteBinLineIcon from "remixicon-react/DeleteBinLineIcon";
import MainLayout from "../Components/Layouts/MainLayout";
import { Link } from "react-router-dom";
import API from "../Middleware/API";
import { useEffect, useState } from "react";

import MediumProgress from "../Components/Need/MediumProgress";
import DeleteModal from "../Components/Modals/DeleteModal";
import UseCaseModal from '../Components/Modals/UseCaseModal';
import Constants from "../Support/Constants";
import axios from "axios";

const Projects = () => {

    const [data, setData] = useState(null);
    const [showModal, setShowModal] = useState(0);
    const [dltId, setDltId] = useState(0);

    const [details, setDetails] = useState({
        show : 0,
        link : "",
        useCase : "",
        type : "",
     });
  
    const showUseCaseModal = (link, useCase, type) => {
  
        setDetails({
           show : details + 1,
           link : link,
           useCase : useCase,
           type : type,
        });
    }

    let [pg, setPg] = useState(1);

    const [allowLoadMore, setAllowLoadMore] = useState(true);

    useEffect(() => {

        if (pg > 1 && allowLoadMore) {

            setAllowLoadMore(false);

            API.get(`projects/${pg}`)
            .then((e) => {

                let tempData = [];

                if (e.data.length == 0) {
                    setAllowLoadMore(false);
                    return
                }

                for (const x of e.data) {

                    tempData.push({
                        title: x.title,
                        date: x.date,
                        link: `/writer/${x.id}`,
                        id: x.id,
                        typex: x.type,
                    })
                }

                setData([...data,...tempData]);
                setAllowLoadMore(true);
            })
            .catch((err) => console.clear())
        }

    }, [pg]);

    const onEndScroll = (event) => {

        const { scrollHeight, scrollTop, clientHeight } = document.documentElement;
        
        if (scrollTop + clientHeight > scrollHeight - 70) {

            if (allowLoadMore) {
                // let pgNo = pg + 1;
                setPg(++pg);
            }

        }

    }

    useEffect(() => {

        window.addEventListener("scroll", onEndScroll);

        return () => window.removeEventListener("scroll", onEndScroll);


    }, []);

    useEffect(() => {

        API.get(`projects/${pg}`)
            .then((e) => {

                let tempData = [];

                if (e.data.length == 0) {
                    setData([]);
                }

                for (const x of e.data) {

                    tempData.push({
                        title: x.title,
                        date: x.date,
                        link: `/${x.type}/${x.id}`,
                        id: x.id,
                        typex: x.type,
                    })
                }

                setData(tempData);

            })
            .catch((err) => console.clear())

    }, []);

    const onDltClick = (id) => {
        setDltId(id);
        setShowModal(showModal + 1);
    }

    return (

        <>

        <UseCaseModal details={details} />

        <DeleteModal show={showModal} id={dltId} />

        <MainLayout title="Projects" projects w100>

            {

                    data === null 
                    
                    ?

                    <div className="center-container">
                        <MediumProgress />
                    </div>

                    :

                
                  <div className="pg-s-width">

                    <div className='d-flex flex-wrap'>

                        <div onClick={() => showUseCaseModal("/writer/", "AIContent", "writer")} className='card box bord d-flex flex-column align-items-center justify-content-center'>
                            <AddCircleLineIcon size={26} />
                            <span className='mt-3'>Create New Project</span>
                        </div>
                        <div onClick={() => showUseCaseModal("/blog/", "AIContent", "blog")} className='card box bord d-flex flex-column align-items-center justify-content-center'>
                            <AddCircleLineIcon size={26} />
                            <span className='mt-3'>Create New Blog</span>
                        </div>

                        {
                            data.map((e) => {

                                return (
                                    <div key={e.id} className='card box d-flex flex-column'>
                                       <Link to={e.link}><h6 className='mt-3 pc-text titlepur'>{e.title.length > 50 ? e.title.substr(0, 50) + "..." : e.title}</h6></Link>
                                       
                                        <div className="usecase-text">
                                          <div className="d-flex justify-content-between pc-bottom">
                                            <span className='mt-3 text-primary'>{e.date}</span>
                                            <span className='mt-3 text-primary typex'> {e.typex}</span>

                                            <DeleteBinLineIcon onClick={() => onDltClick(e.id)} color="red" className="mt-3 dlt-icon" size={18} />
                                          </div>
                                        </div>
                                    </div>
                                )

                            })
                        }

                     </div>

                     </div>

            }



        </MainLayout>

        </>

    );
}

export default Projects;