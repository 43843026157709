// const Buffer = require("buffer").Buffer;
import { Buffer } from "buffer";
const Key = () => {

    const dt = Date.now();
    const rd = ["JSPRB", "IUWGDV", "AJDJH", "WIQUUB", "QWIYFB", "WAKDFHW", "IQWIHDCB", "QAFJJH", "LQYFV", "AFGVF", "UYEBD", "PZPJFS", "XPQUN"];
    const vp = {
        1: "KFN",
        2: "FGS",
        3: "FSP",
        4: "RTD",
        5: "YTS",
        6: "LPE",
        7: "PDJ",
        8: "URB",
        9: "ETP",
        0: "ERS",
    }

    let rd2 = rd[Math.floor(Math.random() * rd.length)];

    let dt2 = dt.toString();
    dt2 = Array.from(dt2);
    dt2 = dt2.map((v) => vp[v]);
    dt2 = dt2.toString().replace(/,/g, "");

    let ec = Buffer.from(`${dt2},${rd2}`).toString("base64");


    return ec;
};

export default Key;