import { useRef, useState, useEffect } from "react";
import { isEmpty, isEmail, isSpecialCharsOk } from "../Support/FormValidator";
import axios from "axios";
import Constants from "../Support/Constants";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Key from "../Support/Key";
import ProgressPage from "../Components/Need/ProgressPage";
import NavbarEasy from "../Components/Need/NavbarEasy";

const Register = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [msg, setMsg] = useState({ color: "white", msg: "" });
    const regForm = useRef();
    const navigate = useNavigate();

    const [isLoggedIn, setIsLoggedIn] = useState(null);

    useEffect(() => {

        axios.get(Constants.apiUrl + `isloggedin?key=${Key()}`, {
            withCredentials: true
        })
            .then((e) => {

                if (e.data.isLoggedIn === true) {
                    window.location.replace("/projects");
                    setIsLoggedIn(true);
                } else {
                    setIsLoggedIn(false);
                }

            })
            .catch((err) => {

                setIsLoggedIn(false);
                console.clear();
            });

    }, []);


    const onRegister = (event) => {

        event.preventDefault();

        //checking all form fields
        if (isEmpty(["name", "email", "cpassword", "password"], regForm.current)) {
            setMsg({ color: "red", msg: "Please fill all the fields !" });
            return;
        }

        //saving form values
        const { name, cpassword, password, email } = regForm.current;

        if (cpassword.value !== password.value) {
            setMsg({ color: "red", msg: "Confirm password is not correct !" });
            return;
        }


        //validating email
        if (!isEmail(email.value)) {
            setMsg({ color: "red", msg: "Please input a valid email !" });
            return;
        }

        if (name.value.length < 4) {
            setMsg({ color: "red", msg: "Name is too short !" });
            return;
        }

        if (email.value.length > 70 || name.value.length > 70) {
            setMsg({ color: "red", msg: "Input is too long !" });
            return;
        }

        if (password.value.length < 8) {
            setMsg({ color: "red", msg: "Password should be more than 7 characters." });
            return;
        }

        //if any prev error removing it
        if (msg.msg != "") {
            setMsg({ color: "white", msg: "" });
        }

        setIsLoading(true);


        axios.post(Constants.apiUrl + `register?key=${Key()}`, {
            name: name.value,
            email: email.value,
            password: password.value,
            cpassword: cpassword.value,
        },
            {
                withCredentials: true,
            })
            .then((e) => {
                setIsLoading(false);
                if (e.data.status == "success") {
                    navigate("/login", { replace: true, state: { msg: "Registration success, please check your email." } });
                }
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
                try {
                    setMsg({ color: 'red', msg: err.response.data.msg });
                }
                catch (err) {

                }

            });



    }

    return (

        isLoggedIn === null

            ? <ProgressPage />

            : !isLoggedIn &&

            <>

                <NavbarEasy />

                <div className="main-container">

                    <div className="form-box">

                        <div className="d-flex justify-content-center align-items-center register-card">

                            <form ref={regForm}>

                                <h5 className="mb-2">Sign Up</h5>

                                <span className="msg mb-2" style={{ color: msg.color }}>{msg.msg}</span>

                                <div className="mb-3 mt-2">
                                    <label className="form-label">Full Name</label>
                                    <input type="text" className="form-control" name="name" />
                                </div>


                                <div className="mb-3">
                                    <label className="form-label">Email address</label>
                                    <input type="email" className="form-control" name="email" />
                                </div>

                                <div className="mb-3">
                                    <label className="form-label">Password</label>
                                    <input type="password" className="form-control" name="password" />
                                </div>

                                <div className="mb-3">
                                    <label className="form-label">Confirm Password</label>
                                    <input type="password" className="form-control" name="cpassword" />
                                </div>

                                {
                                    isLoading
                                        ?
                                        <div className="small-progress mt-4"></div>
                                        :
                                        <button type="submit" className="btn app-btn w-100 mt-2 mb-2" onClick={onRegister}>Sign Up</button>
                                }
                                <label className="form-label" htmlFor="agree">By signing up, you agree to our <a target="_blank" href="https://contentcreato.com/terms/">terms and conditions</a> </label>


                                <p className="my-2">Already have an account?  <Link to="/login"><a>Sign In</a></Link></p>

                            </form>
                        </div>
                    </div>

                </div>

            </>

    )
}


export default Register;