import MediumProgress from "./MediumProgress";
import Constants from "../../Support/Constants";
import { Link } from "react-router-dom";

const ProgressPage = () => {

    return (
        <>
            <nav className="navbar">
                <div className="d-flex justify-content-center align-items-center w-100">
                    <Link to="/" ><a className="navbar-title">{Constants.appName}</a></Link>
                </div>
            </nav>

            <div className="main-container">
                <MediumProgress />
           </div>

       </>
    )
}

export default ProgressPage;