import System from "./System";
import Writer from "./Writer";

import { combineReducers } from "redux";

const rootReducer = combineReducers({
    System,
    Writer
});

export default rootReducer;