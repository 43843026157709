import {
  BrowserRouter,
  Routes,
  Route,
  Navigate
} from "react-router-dom";


import Projects from './Pages/Projects';
import Account from './Pages/Account';
import Credits from './Pages/Credits';
import Login from './Pages/Login';
import Register from './Pages/Register';
import Logout from './Pages/Logout';
import Writer from './Pages/Writer/Writer';
import Blog from './Pages/Blog/Blog';
import Subscription from './Pages/Subscription';
import NotFound from "./Pages/NotFound";

import { useState, useEffect } from 'react';
import axios from 'axios';
import Key from './Support/Key';
import Constants from './Support/Constants';

import Protected from './Middleware/Protected';


function App() {

  const [isLoggedIn, setIsLoggedIn] = useState(null);

  useEffect(() => {

    axios.get(Constants.apiUrl + `isloggedin?key=${Key()}`, {
      withCredentials: true
    })
      .then((e) => {

        if (e.data.isLoggedIn === true) {
          //window.location.replace("/projects");
          setIsLoggedIn(true);
        } else {
          setIsLoggedIn(false);
        }

      })
      .catch((err) => {

        setIsLoggedIn(false);
        console.clear();
      });

  }, []);

  return (

    <div className='app'>
      <Routes>

        <Route path='*' element={<NotFound />} />

        <Route exact path="/" element={<Navigate to="/login" />} />

        <Route exact path="login" element={<Login />} />
        <Route exact path="register" element={<Register />} />
        <Route exact path="logout" element={<Logout />} />

        <Route exact path="projects"
          element={
            <Protected isLoggedIn={isLoggedIn}><Projects /></Protected>
          }
        />

        <Route exact path="account"
          element={
            <Protected isLoggedIn={isLoggedIn}><Account /></Protected>
          }
        />

        <Route exact path="credits"
          element={
            <Protected isLoggedIn={isLoggedIn}><Credits /></Protected>
          }
        />

        <Route exact path="subscription"
          element={
            <Protected isLoggedIn={isLoggedIn}><Subscription /></Protected>
          }
        />

        <Route exact path="writer/:id"
          element={
            <Protected isLoggedIn={isLoggedIn}><Writer /></Protected>
          }
        />
        <Route exact path="blog/:id"
          element={
            <Protected isLoggedIn={isLoggedIn}><Blog /></Protected>
          }
        />


      </Routes>
    </div>

  );
}

export default App;
