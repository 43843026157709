import { useState,useRef, useEffect } from "react";
import CloseLineIcon from "remixicon-react/CloseLineIcon";
import SmallProgress from "../Need/SmallProgress";
import API from "../../Middleware/API";
import { useNavigate } from "react-router-dom";

const UseCaseModal = ({details}) => {

   const [showModalBtn , setShowModalBtn] = useState(true);
   const [errMsg, setErrMsg] = useState("");
   const [title, setTitle] = useState("");
   const [type, setType] = useState("");
   const modal = useRef();
   const navigate = useNavigate();

   const modalBtnClick = () => {

    if(!title){
        return;
    }

    if(title.length < 6){
        setErrMsg("Title should be atleast 6 characters long !");
        return;
    }
   
    setShowModalBtn(false);

      API.post("addproject", {
          title : title,
          type : details.type,
          useCase : details.useCase,

      })
      
      .then((e) => {

        setShowModalBtn(true);

        if(e.data.projectId){
            navigate(details.link + `${e.data.projectId}`, { replace: true,state : { msg : "new-project" } });
            //setErrMsg(e.data.msg);
        }

      })
      .catch((err) => {

          try{
              setErrMsg(err.response.data.msg)
          }
          catch(err){
          }

          console.clear();
          setShowModalBtn(true);
         
       })


      if(errMsg) setErrMsg("");

   }
   const onTypeChange = (e) => {
    console.log(`Option selected:`, type);
    setType(e.target.value);
  };
   const hideModal = () => {
      modal.current.classList.remove("show-modal2");
   }

   useEffect(() => {

       if(details.show) modal.current.classList.add("show-modal2");

   },[details]);

    return (

        <div ref={modal} className={"modal2"}>

        <div className='card modal2-box'>

            <CloseLineIcon onClick={hideModal} className="modal2-close-icon" size={26} />

            <h6 className='mb-3'>Give a name to your project !</h6>

            <div className="mb-3">
                <input value={title} onChange={(e) => setTitle(e.target.value)} type="text" className="form-control" placeholder="Power Of AI" />
            </div>
            

            {
                errMsg && <span className="text-danger mb-2">{errMsg}</span>
            }
            

            {
                    showModalBtn
                    ?
                    <button onClick={modalBtnClick} type="button" className="btn app-btn">Let's Start</button>
                    :
                    <SmallProgress />
            }

        </div>

    </div>


    );
}

export default UseCaseModal;