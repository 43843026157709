import Navbar from '../Need/Navbar';
import BottomMenu from '../Need/BottomMenu';

const MainLayout = (props) => {

    // const Logout = () => {

    //     axios.get(Constants.apiUrl + "logout", {withCredentials : true})
    //     .then((e) => {
    //         router.push("/login");
    //     })
    //     .catch((err) => {

    //     });

    // };

    return (

        <>
           <Navbar title={props.title} />

           <BottomMenu active={props.title} />

            <div className={props.isTwoLayout ? "container-fluid" : "container-fluid mc"} >

                <div className={props.w100 ? "row w-100" : "row"}>

                    {/* <div className="col-2 left-sb">

                        <div className="side-menu-container">

                            <h5 className="brand-heading"><a href="/">{Constants.appName}</a></h5>

                            <ul className="side-menu">

                                <Link to="/projects"><li className={props.projects ? "side-menu-link active-menu" : "side-menu-link"}>
                                    <FolderLineIcon size={16} className="menu-icon" />
                                    Projects
                                </li></Link>

                                <Link to="/account"><a><li className={props.account ? "side-menu-link active-menu" : "side-menu-link"}>
                                    <UserLineIcon size={16} className="menu-icon" />
                                    Account
                                </li></a></Link>

                                <Link to="/credits"><a><li className={props.credits ? "side-menu-link active-menu" : "side-menu-link"}>
                                    <BankCardLineIcon size={16} className="menu-icon" />
                                    Credits
                                </li></a></Link>

                                <Link to="/logout"><li className="side-menu-link">
                                    <LogoutCircleRLineIcon size={16} className="menu-icon" />
                                    Logout
                                </li></Link>

                            </ul>

                        </div>

                    </div> */}

                    <div className={'main-content'}>

                        {/* <Navbar title={props.title} /> */}

                        <div className="page-container pt-2">

                            {/* <div className="page-heading">
                                <h4>{props.title}</h4>
                            </div> */}

                            {props.children}

                        </div>

                    </div>


                    {/* {
                            props.rightSideBar &&

                            <div className="col-3 my-2 right-sb">
                                <div className="page-heading">
                                    <h4>Info</h4>
                                </div>
                                {props.rightSideBarContent()}
                            </div>
                        } */}

                </div>



            </div>


        </>

    );
}

export default MainLayout;