import { useState,useRef, useEffect } from "react";
import CloseLineIcon from "remixicon-react/CloseLineIcon";
import API from "../../Middleware/API";
import SmallProgress from "../Need/SmallProgress";

const PasswordModal = ({show}) => {

    const passwordInit = {
        oldPassword : "",
        newPassword : "",
        confirmPassword : ""
    };
    
   const [password, setPassword] = useState(passwordInit);

   const [showModalBtn , setShowModalBtn] = useState(true);
   const [errMsg, setErrMsg] = useState("");
   const [okMsg, setOkMsg] = useState("");
   const modal = useRef();

   const modalBtnClick = () => {

      if(!password.oldPassword || !password.newPassword || !password.confirmPassword){
         return;
      }

      if(password.newPassword !== password.confirmPassword){
          setErrMsg("Confirm password is wrong !");
          return;
      }

      if(password.newPassword.length < 8){
        setErrMsg("Password should be more than 7 characters.");
        return;
      }

      setShowModalBtn(false);

      if(errMsg) setErrMsg("");

      API.post("changepassword",{
          oldPassword : password.oldPassword,
          newPassword : password.newPassword,
          newConfirmPassword : password.confirmPassword
      })
      .then((e) => {
          setOkMsg(e.data.msg);
          setPassword(passwordInit);
          setShowModalBtn(true);
      })
      .catch((err) => {

        setErrMsg(err.response.data.msg)
        setShowModalBtn(true);

      });
     

   }

//    const passBtnClick = (to) => {
//       modal.current.classList.add("show-modal2");
//    }

   const hideModal = () => {
      modal.current.classList.remove("show-modal2");
   }

   useEffect(() => {

       if(show) modal.current.classList.add("show-modal2");

       

   },[show]);

    return (

        <div ref={modal} className={"modal2"}>

        <div className='card modal2-box'>

            <CloseLineIcon onClick={hideModal} className="modal2-close-icon" size={26} />
            <h6 className='mb-3'>Old Password</h6>
            <div className="mb-3">
                <input value={password.oldPassword} onChange={(e) => setPassword({...password, oldPassword : e.target.value})} type="password" className="form-control" placeholder="Old Password" />
            </div>

            <h6 className='mb-3'>New Password</h6>
            <div className="mb-3">
                <input value={password.newPassword} onChange={(e) => setPassword({...password, newPassword : e.target.value})} type="password" className="form-control" placeholder="New Password" />
            </div>

           
            <div className="mb-3">
                <input value={password.confirmPassword} onChange={(e) => setPassword({...password, confirmPassword : e.target.value})} type="password" className="form-control" placeholder="Confirm Password" />
            </div>

            {
                errMsg && <span className="text-danger mb-2">{errMsg}</span>
            }

            {
                okMsg && <span className="text-success mb-2">{okMsg}</span>
            }
            

            {
                    showModalBtn
                    ?
                    <button onClick={modalBtnClick} type="button" className="btn app-btn">Change</button>
                    :
                    <SmallProgress />
            }

        </div>

    </div>


    );
}

export default PasswordModal;