// App.js
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import PaymentForm from "./PaymentForm";
import Constants from "../../Support/Constants";

// when you toggle to live mode, you should add the live publishale key.
const stripePromise = loadStripe(Constants.stripeKey);

function Payment({plan}) {
  return (
    <div className="App">
      {/* Elements is the provider that lets us access the Stripe object. 
         It takes the promise that is returned from loadStripe*/}
      <Elements stripe={stripePromise}>
        <PaymentForm plan={plan} /> 
      </Elements>
    </div>
  );
}

export default Payment;