import { useState, useRef, useEffect } from "react";
import CloseLineIcon from "remixicon-react/CloseLineIcon";
import Payment from "../Need/Payment";
import API from "../../Middleware/API";
import { Link } from "react-router-dom";
import SmallProgress from "../Need/SmallProgress";


const PaymentModal = ({ show, plan }) => {

    const modal = useRef();
    const [isSubActive, setIsSubActive] = useState(null);
    const [planName, setPlanName] = useState("");

    const modalBtnClick = () => {
        modal.current.classList.remove("show-modal2");
    }

    const hideModal = () => {
        modal.current.classList.remove("show-modal2");
    }

    useEffect(() => {

        if (show) modal.current.classList.add("show-modal2");


    }, [show]);

    useEffect(() => {

        API.get("checkactivesub")
            .then((e) => {

                if (e.data.isSubActive) {

                    setIsSubActive(true);
                    setPlanName(e.data.plan);

                }

            })
            .catch((err) => {

                if (err.response.data.isSubActive === false) {
                    setIsSubActive(false);
                }

                console.clear();

            });

    }, []);

    return (

        <div ref={modal} className={"modal2"}>

            <div className='card modal2-box'>

                <CloseLineIcon onClick={hideModal} className="modal2-close-icon" size={26} />

                {

                isSubActive === null 

                ?

                <SmallProgress />

                :

                isSubActive === true

                ?

                <div className="w-100">

                   <p className="text-center mx-4">You have an active subscription, which is {planName}.<br/>Unsubscribe this plan from <Link to="/account">here</Link> to subscribe another plan.</p>

                </div>

                :
   

                <div className="w-100">

                    <Payment plan={plan} />

                </div>

                }



                {/* <button onClick={modalBtnClick} type="button" className="btn app-btn">Got It</button> */}

            </div>

        </div>


    );
}

export default PaymentModal;