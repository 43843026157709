import { useState,useRef, useEffect } from "react";
import CloseLineIcon from "remixicon-react/CloseLineIcon";
import SmallProgress from "../Need/SmallProgress";
import axios from "axios";
import Constants from "../../Support/Constants";
import Key from "../../Support/Key";
import { isEmail } from "../../Support/FormValidator";

const ForgotPassModal = ({show}) => {

   const [showModalBtn , setShowModalBtn] = useState(true);
   const [errMsg, setErrMsg] = useState("");
   const [okMsg, setOkMsg] = useState("");
   const [data, setData] = useState({email : ""});
   const modal = useRef();

   const modalBtnClick = () => {

    if(!data.email){
        return;
    }

    if(!isEmail(data.email)){
        setErrMsg("Please use a valid email.");
        return;
    }

      setShowModalBtn(false);

      axios.post(Constants.apiUrl + `forgotpassword?key=${Key()}`, {
          email : data.email
      })
      .then((e) => {

         setOkMsg(e.data.msg)

         setShowModalBtn(null);

        // if(!e.data.isSubscribed){
        //     setErrMsg(e.data.msg);
        // }
        // else{

        //     window.location.replace("/credits");
        //     modal.current.classList.remove("show-modal2");

        // }

      })
      .catch((err) => {
          setErrMsg(err.response.data.msg);
          setShowModalBtn(true);
          console.clear()
       })


      if(errMsg) setErrMsg("");
      if(okMsg) setOkMsg("");

   }

   const hideModal = () => {

    //   setData({email : ""});
    //   if(errMsg) setErrMsg("");
    //   if(okMsg) setOkMsg("");
    //   setShowModalBtn(true);

      modal.current.classList.remove("show-modal2");
   }

   useEffect(() => {

       if(show) modal.current.classList.add("show-modal2");

   },[show]);

    return (

        <div ref={modal} className={"modal2"}>

        <div className='card modal2-box'>

            <CloseLineIcon onClick={hideModal} className="modal2-close-icon" size={26} />

            <h6 className='mb-3'>Password Change Request</h6>

            <div className="mb-3">
                <input value={data.email} onChange={(e) => setData({email: e.target.value})} type="email" className="form-control" placeholder="Enter Account Email" />
            </div>

            {
                errMsg && <span className="text-danger mb-2">{errMsg}</span>
            }

            {
                okMsg && <span className="text-success mb-2">{okMsg}</span>
            }
            

            {
                    showModalBtn  === true
                    ?
                    <button onClick={modalBtnClick} type="button" className="btn app-btn">Submit</button>
                    :
                    showModalBtn === false
                    ?
                    <SmallProgress />
                    :
                    <></>
            }

        </div>

    </div>


    );
}

export default ForgotPassModal;