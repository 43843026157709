import { useState,useRef, useEffect } from "react";
import CloseLineIcon from "remixicon-react/CloseLineIcon";
import SmallProgress from "../Need/SmallProgress";
import API from "../../Middleware/API";

const AddCardModal = ({show}) => {

   const [showModalBtn , setShowModalBtn] = useState(true);
   const [errMsg, setErrMsg] = useState("");
   const [data, setData] = useState({cardKey : ""});
   const modal = useRef();

   const modalBtnClick = () => {

    if(!data.cardKey){
        return;
    }

      setShowModalBtn(false);

      API.post("subscribe", {
          cardKey : data.cardKey
      })
      .then((e) => {

        setShowModalBtn(true);

        if(!e.data.isSubscribed){
            setErrMsg(e.data.msg);
        }
        else{

            window.location.replace("/credits");
            modal.current.classList.remove("show-modal2");

        }

      })
      .catch((err) => {
          setShowModalBtn(true);
          console.clear()
       })


      if(errMsg) setErrMsg("");

   }

   const hideModal = () => {
      modal.current.classList.remove("show-modal2");
   }

   useEffect(() => {

       if(show) modal.current.classList.add("show-modal2");

   },[show]);

    return (

        <div ref={modal} className={"modal2"}>

        <div className='card modal2-box'>

            <CloseLineIcon onClick={hideModal} className="modal2-close-icon" size={26} />

            <h6 className='mb-3'>Subscription Card</h6>

            <div className="mb-3">
                <input value={data.cardKey} onChange={(e) => setData({...data, cardKey: e.target.value})} type="text" className="form-control" placeholder="Enter Subscription Card Key" />
            </div>

            {
                errMsg && <span className="text-danger mb-2">{errMsg}</span>
            }
            

            {
                    showModalBtn
                    ?
                    <button onClick={modalBtnClick} type="button" className="btn app-btn">Submit</button>
                    :
                    <SmallProgress />
            }

        </div>

    </div>


    );
}

export default AddCardModal;