export const System = (data) => {
    return {
        type : "SYSTEM",
        payload : data,
    }
}


export const Writer = (data) => {
    return {
        type : "WRITER",
        payload : data,
    }
}




