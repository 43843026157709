const initialState = "";

const Writer = (state = initialState, action) => {
    switch(action.type){
        case "WRITER" :
            return action.payload;
        default : 
            return state;
    }
}

export default Writer;