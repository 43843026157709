import axios from "axios"
import Key from "../Support/Key";
import Constants from "../Support/Constants";

class API{

    //window.location.replace("/login");
    async get(path){

        try{

            const key = Key();

            const respData = await axios.get(Constants.apiUrl + `${path}?key=${key}`, { withCredentials : true });
    
            return respData;

        }
        catch(err){

            console.clear();

            if(err.response.status === 401){
                window.location.replace("/login");
            }
            else{
                throw err;
            }
        }
       

    }

    async post(path,data){

        try{

            const key = Key();

            const respData = await axios.post(Constants.apiUrl + `${path}?key=${key}`, 
                             data, 
                            { withCredentials : true }
                            );

            return respData;

        }
        catch(err){

            if(err.response.status === 401){
                window.location.replace("/login");
            }
            else{
                throw err;
            }
        }

    }
}

export default new API();
